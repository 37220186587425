import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line,
    Label, LabelList, Legend, ResponsiveContainer
} from 'recharts';
import styles from "./Charts.module.css"
import { ChartsConfig } from "./ChartsConfig"
import { constants } from '../../constants/constants';
import { useState, useLayoutEffect, useEffect } from "react"




const getChartsConfigObj = (stat) => {
    switch (stat) {
        case constants.StatsOptions.ServePoints:
            return ChartsConfig.ServePoints;
        case constants.StatsOptions.BreakPoints:
            return ChartsConfig.BreakPoints;
        case constants.StatsOptions.BreakPointsConverted:
            return ChartsConfig.BreakPointsConverted;
        case constants.StatsOptions.ReturnPoints:
            return ChartsConfig.ReturnPoints;
        case constants.StatsOptions.AcesAndDoubleFaults:
            return ChartsConfig.AcesAndDoubleFaults;
        case constants.StatsOptions.DominanceRatio:
            return ChartsConfig.DominanceRatio;
        default:
            return ChartsConfig.ServePoints;
    }
}

const getCustomTooltip = (props) => {
    return <div
        className={styles.barchartTooltip}
    >
        <span className={styles.barchartTooltipText}>
            {`Click to get Points distribution Pie Chart`}
        </span>
    </div>
}
const getWrapperWidth = (length, ratio) => {
    if (length === undefined) {
        return 3 * ratio * ratio
    }
    else if (length < 3) {
        return 3 * ratio * ratio
    }
    else return length * ratio * ratio
}
export const getServeChartDistributn = (payload) => [
    [{ key: '1st Serves Won', value: parseInt(payload.player_1stWon) },
    { key: '1st Serves Lost', value: parseInt(payload.player_1stIn - payload.player_1stWon) },
    { key: 'DFs', value: parseInt(payload.player_df) },
    { key: '2nd Serves Won', value: parseInt(payload.player_2ndWon) },
    { key: '2nd Serves Lost', value: parseInt(payload.opp_2ndWon) }]
    ,
    {
        opp_name_tourney_year: `Vs ${payload.opp_name},${payload.tourney_and_year}`,
        player_svpt: payload.player_svpt,
        player_1stIn: payload.player_1stIn,
        player_2ndIn: payload.player_svpt - payload.player_1stIn - payload.player_df,
    }]

export const getReturnChartDistributn = (payload) => [
    [{ key: '1st Returns Won', value: parseInt(payload.opp_1stIn - payload.opp_1stWon) },
    { key: '1st Return Lost', value: parseInt(payload.opp_1stWon) },
    { key: 'Opponent DFs', value: parseInt(payload.opp_df) },
    { key: '2nd Returns Won', value: parseInt(payload.opp_svpt - payload.opp_1stIn - payload.opp_2ndWon - payload.opp_df) },
    { key: '2nd Returns Lost', value: parseInt(payload.opp_2ndWon) },]
    ,
    {
        opp_name_tourney_year: `Vs ${payload.opp_name},${payload.tourney_and_year}`,
        player_serves_faced: payload.opp_svpt,
        player_1st_serves_faced: payload.opp_1stIn,
        player_2nd_serves_faced: payload.opp_svpt - payload.opp_1stIn - payload.opp_df
    }
]

export default function Charts(props) {
    const { data, stat, setServeChartDistributn, setReturnChartDistributn } = props;
    const [sizes, setSizes] = useState({
        wrapperRatio: 16,
        xyAxisFontSize: '12.5px',
        xAxisPaddingBottom: 5,
        xAxisHeight: 20,
        barwidth: 25,
        xAxisLabelFontSize: '12.5px',
        xAxisLabelFontSize: '12.5px',
    });
    useLayoutEffect(() => {
        if (window.innerWidth < 850) {
            setSizes(
                {
                    wrapperRatio: 14.5,
                    xyAxisFontSize: '10px',
                    xAxisHeight: 16,
                    xAxisPaddingBottom: 5,
                    barwidth: 20,
                    xAxisLabelFontSize: '10px',
                    xAxisLabelFontSize: '10px',
                }
            )
        }
    }, [])
    const [wrapperWidth, setWrapperWidth] = useState(getWrapperWidth(data?.length, sizes.wrapperRatio));
    useEffect(() => {
        setWrapperWidth(getWrapperWidth(data?.length, sizes.wrapperRatio))
    }, [data])
    const getLabel = (props) => {
        const value = props.payload.value;
        const { x, y } = props
        return (
            <g>
                <text
                    style={{
                        fill: "rgb(255,255,255)",
                        fontSize: sizes.xyAxisFontSize
                    }}
                    x={x} y={y + sizes.xAxisPaddingBottom} textAnchor="middle">
                    {value}
                </text>
            </g>
        )
    }
    // ??? Change wrapperWidth acc to window size

    const currentChartsConfig = getChartsConfigObj(stat);

    const setPointsDistributnData = ({ payload }) => {
        if (stat === constants.StatsOptions.ServePoints) {
            setServeChartDistributn(getServeChartDistributn(payload));
        }
        else if (stat === constants.StatsOptions.ReturnPoints) {
            setReturnChartDistributn(getReturnChartDistributn(payload))
        }
    }

    if (currentChartsConfig.chartType === "Bar Chart") {
        return (
            <section className={styles.ResponsiveContainerWrapper}>
                <ResponsiveContainer height="100%" width={wrapperWidth}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 20,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                        barGap={0}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        {
                            currentChartsConfig.xAxis.map((el) =>
                                <XAxis
                                    height={sizes.xAxisHeight}
                                    xAxisId={el.xAxisId}
                                    dataKey={el.datakey}
                                    axisLine={false}
                                    tick={getLabel}
                                    tickLine={false}>
                                </XAxis>
                            )
                        }

                        {[constants.StatsOptions.ServePoints, constants.StatsOptions.ReturnPoints].includes(stat) &&
                            <Tooltip content={getCustomTooltip}
                                isAnimationActive={false}
                            />}
                        <YAxis unit={currentChartsConfig.yAxis.unit}
                            type={currentChartsConfig.yAxis.type}
                            axisLine={false}
                            tickLine={false}
                            style={{
                                fill: currentChartsConfig.yAxis.fill,
                                // ??? Change fontSize acc to window size
                                fontSize: sizes.xyAxisFontSize,
                            }}
                        />
                        <Legend
                            style={{
                                fill: 'rgb(255,255,255)',
                                // ??? Change fontSize acc to window size
                                fontSize: '12.5px'
                            }}
                            align='left'
                            verticalAlign='top'
                            layout='horizontal'
                            wrapperStyle={{ top: 0, left: 50 }} />


                        {currentChartsConfig.bars.map((el) =>
                            <Bar
                                // ??? Change barwidth acc to window size
                                barSize={sizes.barwidth}
                                name={`${el.name}`}
                                dataKey={`${el.dataKey}`}
                                fill={`${el.fill}`}
                                onClick={setPointsDistributnData}
                            >
                                <LabelList dataKey={`${el.labelConfig.dataKey}`}
                                    style={{
                                        fill: `${el.labelConfig.fill}`,
                                        // ??? Change fontSize acc to window size
                                        fontSize: `${sizes.xAxisLabelFontSize}`,
                                    }}
                                    position="top" />

                            </Bar>

                        )}

                    </BarChart>

                </ResponsiveContainer>
            </section>
        )
    }
    else if (currentChartsConfig.chartType === "Line Chart") {
        return (
            <section className={styles.ResponsiveContainerWrapper}>
                <ResponsiveContainer height="95%" width={wrapperWidth}>

                    <LineChart
                        data={data}
                        margin={{
                            top: 20,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1"
                            vertical={false}
                        />

                        {
                            currentChartsConfig.xAxis.map((el) =>
                                <XAxis
                                    padding={{
                                        left: 50,
                                        right: 50
                                    }}
                                    height={sizes.xAxisHeight}
                                    xAxisId={el.xAxisId}
                                    dataKey={el.datakey}
                                    axisLine={false}
                                    tick={getLabel}
                                    tickLine={false}>
                                </XAxis>
                            )
                        }

                        <YAxis unit={currentChartsConfig.yAxis.unit}
                            type={currentChartsConfig.yAxis.type}
                            axisLine={false}
                            tickLine={false}
                            style={{
                                fill: currentChartsConfig.yAxis.fill,
                                // ??? Change fontSize acc to window size
                                fontSize: sizes.xyAxisFontSize,
                            }}
                        />
                        <Legend
                            align='left'
                            verticalAlign='top'
                            layout='horizontal'
                            wrapperStyle={{ top: 0, left: 50 }}
                        />
                        {currentChartsConfig.lines.map(el =>
                            <Line
                                dot={false}
                                strokeWidth={el.strokeWidth}
                                name={el.name}
                                dataKey={el.dataKey}
                                stroke={el.stroke}
                            >
                                <LabelList dataKey={el.dataKey}
                                    style={{
                                        fontSize: `${sizes.xAxisLabelFontSize}`,
                                        fill: el.labelConfig.fill,
                                    }}
                                    position="top" />
                            </Line>
                        )
                        }


                    </LineChart>
                </ResponsiveContainer>
            </section>)
    }

}