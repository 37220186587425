const StatsOptions = {
    ServePoints: 'Serve points',
    ReturnPoints: 'Return points',
    BreakPoints: 'Break points',
    BreakPointsConverted: "Break points converted",
    AcesAndDoubleFaults: 'Aces & Double Faults',
    DominanceRatio: 'Dominance Ratio',
}
const TourOptions = {
    ATP: 'ATP',
    WTA: 'WTA',
}
const CircuitOptions = {
    Main: 'main',
    Challenger: 'challenger',
}
const YearOptions = {
    Year1: '2020',
    Year2: '2021',
    Year3: '2022',
}
const SurfaceOptions = {
    Hard: 'Hard',
    Clay: 'Clay',
    Grass: 'Grass',
}
const ResultOptions = {
    Win: 'Win',
    Loss: 'Loss',
}
const RoundOptions = {
    F: 'F',
    SF: 'SF',
    QF: 'QF',
    R16: 'R16',
    R32: 'R32',
    R64: 'R64',
    R128: 'R128',
    RR: 'RR',
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
}
export const constants = {
    StatsOptions: StatsOptions,
    autoCData: {
        Tour: Object.values(TourOptions),
        Circuit: Object.values(CircuitOptions),
        Year: Object.values(YearOptions),
        Surface: Object.values(SurfaceOptions),
        Result: Object.values(ResultOptions),
        Round: Object.values(RoundOptions),
        StatsOption: Object.values(StatsOptions)
    },

    autoCLabel: {
        Tour: 'Select Tour',
        Circuit: 'Select Circuit',
        Year: 'Select Year',
        PlayerName: 'Select Player Name',
        Surface: 'Select Surface',
        Result: 'Select Result',
        OpponentNames: 'Select Opponent Names',
        TourneyNames: 'Select Tourney Names',
        Round: 'Select Round',
        StatsOption: 'Select Stat'

    },
    field: {
        Tour: 'Tour',
        Circuit: 'Circuit',
        Year: 'Year',
        PlayerName: 'Player Name',
        Surface: 'Surface',
        Result: 'Result',
        OpponentNames: 'Opponent Names',
        TourneyNames: 'Tourney Names',
        Round: 'Round',
        StatsOption: 'Stats',
        ServePoints: 'Serve Points',
        ReturnPoints: 'Return Points',
    },
    InfoLabel: {
        MatchDetails: "Match",
        TotalMatches: "Total matches",
        TotalServesMade: "Total serves made",
        TotalFirstServesMade: "Total 1st serves made",
        TotalSecondServesMade: "Total 2nd serves made",
        TotalServesFaced: "Total serves faced",
        TotalFirstServesFaced: "Total 1st serves faced",
        TotalSecondServesFaced: "total 2nd serves faced",
    },
    Action: {
        SetTour: 'SET_TOUR',
        SetCircuit: 'SET_CIRCUIT',
        SetPlayername: 'SET_PLAYERNAME',
        SetSurface: 'SET_SURFACE',
        SetResult: 'SET_RESULT',
        SetSelectedYears: 'SET_YEARS',
        SetOpponents: 'SET_OPPONENTS',
        SetTourneys: 'SET_TOURNEYS',
        SetRounds: 'SET_ROUNDS',
        SetStatOptions: 'SET_STATOPTIONS',
        ClearOnTourCircuitChange: 'CLEAR_ON_TOUR_CIRCUIT_CHANGE',
    },
    Path: {
        LandingPage: "/",
        Stats: "/stats",
        LogInPassword: "/log-in-password",
        LogInOtp: "/log-in-otp",
        SignUp: "/sign-up",
        ResetPassword: "/reset-password",
        ForgotPassword: "/forgot-password",
        PageNotFound:"page-not-found",
    },
    MessageCode: {
        SERVICE_UNAVAILABLE: "serviceUnavailable",
        BAD_REQUEST: "badRequest",
        UNAUTHORISED: "unAuthorised",

        EMAIL_VERIF_OTP_SENT: "emailVerifOtpSent",
        EMAIL_VERIF_OTP_VERIFIED: "otpSuccessfullyVerified",
        OTP_NOT_GENERATED: "otpNotGenerated",
        OTP_LIMIT_EXCEEDED: "otpLimitExceeded",
        INCORRECT_OTP: "incorrectOtp",
        EMAIL_ALREADY_VERIFIED: "emailAlreadyVerified",

        LOGGED_IN_SUCCESSFULLY: "loggedInSuccessfully",
        INCORRECT_PASSWORD: "incorrectPassword",
        LOGIN_OTP_SENT: "loginOtpSent",
        LOGIN_OTP_VERIFIED: "loginOtpVerified",

        PASSWORD_RESET_SUCCESSFUL: "passwordResetSuccessful",

        FORGOT_PASSWORD_OTP_SENT: "forgotPasswordOtpSent",
        FORGOT_PASSWORD_RESET_SUCCESSFUL: "forgotPasswordResetSuccessful",
        OTP_VERIFY_LIMIT_REACHED: "otpVerifyLimitReached",

        LOGGED_OUT_SUCCESSFULLY: "loggedOutSuccessfully",
        DAILY_API_LIMIT_EXCEEDED: "dailyApiLimitExceeded",
    },
    Message: {
        TYPE_ERROR: "error",
        TYPE_SUCCESS: "success",
        BAD_REQUEST: "Bad Request",
        OTP_LIMIT_EXCEEDED: "Your OTP limit is exceeded.Try again after 12 hrs",
        UNAUTHORISED: "You are not an Authorised User",
        OTP_SENT: "OTP sent to your Email ID. OTP will expire in 60 seconds",
        OTP_NOT_GENERATED: "OTP not generated or OTP expired",
        OTP_VERIFY_LIMIT_REACHED: "You have reached the maximum OTP attempts allowed",
        INCORRECT_OTP: "Incorrect OTP",
        LOGIN_OTP_VERIFIED: "You have Logged In successfully",
        PASSWORD_RESET_SUCCESSFUL: "Your password is reset successfully",
        EMAIL_VERIF_OTP_VERIFIED: "Email verified successfully. You are now Logged In",
        TOUR_OR_CIRCUIT_NOT_SELECTED: "Select Tour or Circuit",
        SERVER_ERROR: "Server is down. Please try again Later",
        SELECT_TOUR_VALUE: "Select Tour value",
        SELECT_CIRCUIT_VALUE: "Select Circuit value",
        SELECT_PLAYERNAME_VALUE: "Select Playername value",
        SELECT_YEAR_OR_SURFACE_VALUE: "Select Year Or Surface Value",
        LOGGED_OUT_SUCCESSFULLY: "You have logged out successfully",
        ENTER_A_VALID_EMAIL: "Enter a valid email",
        ENTER_A_VALID_PASSWORD: "Enter a valid Password. 6 to 16 characters required",
        ENTER_A_VALID_OTP: "Enter valid 6 digit OTP",
        DAILY_API_LIMIT_EXCEEDED:"You have finished daily usage limit. Try again after 12 hrs",
        MAXIMUM_5_VALUES_CAN_BE_SELECTED:"Maximun 5 values can be selected",
        MAXIMUM_3_VALUES_CAN_BE_SELECTED:"Maximun 3 values can be selected",
        MAXIMUM_10_ROWS_CAN_BE_ADDED:"Maximum 10 rows can be added",
    }
}
