import styles from "./MeanTable.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css"
export default function MeanTable(props) {
    const { dataForMean, setDataForMean } = props;
    const removeRow = (ID) => {
        setDataForMean(dataForMean.filter(el => el.id !== ID))
    }
    return (
        <section className={styles.MeanTableWrapper}>
            <p className={styles.MeanTableHeading}>Average table</p>
            <table className={styles.MeanTable}>
                <thead>
                    <tr>
                        <th>Combination</th>
                        <th>1st Serves In</th>
                        <th>1st Serves Won</th>
                        <th>2nd Serves Won</th>
                        <th>1st Return Points Won</th>
                        <th>2nd Return Points Won</th>
                        <th>Break Points SAVED</th>
                        <th>Break Points CONVERTED</th>
                        <th>Dominance Ratio</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                {
                    (dataForMean.length === 0) ?
                        <tbody className={styles.NoDataAdded}>
                            <tr><td colspan="10">No Data Added</td></tr></tbody>
                        :
                        <tbody className={styles.DataBody}>
                            {
                                dataForMean.map(
                                    row =>
                                        <tr className={styles.MeanTableDataRow}>
                                            <td>{row.combination}</td>
                                            <td>{row.avg_player_1st_In_prc}</td>
                                            <td>{row.avg_player_1stprc}</td>
                                            <td>{row.avg_player_2ndprc}</td>
                                            <td>{row.avg_player_1st_retprc}</td>
                                            <td>{row.avg_player_2nd_retprc}</td>
                                            <td>{row.avg_player_bpSavedprc}</td>
                                            <td>{100 - row.avg_opp_bpSavedprc}</td>
                                            <td>{row.avg_player_dr}</td>
                                            <td><button onClick={() => removeRow(row.id)} className={globalStyles.RemoveButton}>X</button></td>
                                        </tr>

                                )
                            }
                        </tbody>
                }
                <tfoot className={styles.MeanTableFooter}>
                    <tr>
                        <td colspan="10">
                            The Values are in % except Dominance Ratio
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section >
    )
}