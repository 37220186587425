
import styles from "./LandingPage.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css";
import { Switch, Route ,Redirect} from "react-router-dom";
import { constants } from "../../constants/constants";
import SignUpPage from "../SignUpPage/SignUpPage";
import LoginWithOtpPage from "../LoginWithOtpPage/LoginWithOtpPage";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage";
import LoginWithPasswordPage from "../LoginWithPasswordPage/LoginWithPasswordPage";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function LandingPage({ setMessageSettings, setLoading, loggedIn }) {
    return (
        <Switch>
            <Route path={constants.Path.SignUp} exact>
                <SignUpPage
                    setMessageSettings={setMessageSettings}
                    setLoading={setLoading}
                    loggedIn={loggedIn}

                />
            </Route>

            <Route path={constants.Path.ResetPassword} exact>
                <ResetPasswordPage
                    setMessageSettings={setMessageSettings}
                    setLoading={setLoading}

                />
            </Route>

            <Route path={constants.Path.LogInOtp} exact>
                <LoginWithOtpPage
                    setMessageSettings={setMessageSettings}
                    setLoading={setLoading}
                    loggedIn={loggedIn}

                />
            </Route>

            <Route path={constants.Path.LogInPassword} exact>
                <LoginWithPasswordPage
                    setMessageSettings={setMessageSettings}
                    setLoading={setLoading}
                    loggedIn={loggedIn}

                />
            </Route>

            <Route path={constants.Path.ForgotPassword} exact>
                <ForgotPasswordPage
                    setMessageSettings={setMessageSettings}
                    setLoading={setLoading}
                />
            </Route>

            <Route path={constants.Path.LandingPage} exact>
                <section className={styles.LandingPageWrapper}>

                    <section className={`${styles.Img11st} ${styles.LandingPageImageWrapper}`}>
                        <section className={styles.LandingPageTextWrapper}>
                            <p className={styles.LandingPageText1}>Form your own</p>
                            <p className={styles.LandingPageText2}>Prematch</p>
                            <p className={styles.LandingPageText2}>Insights</p>
                            <p className={styles.LandingPageText1}>with tennisprematch.com</p>
                        </section>
                    </section>

                    <section className={`${styles.Img22nd} ${styles.LandingPageImageWrapper}`}>
                        <section className={styles.LandingPageTextWrapper}>
                            <p className={styles.LandingPageText1}>Customise your</p>
                            <p className={styles.LandingPageText2}>Selections</p>
                        </section>
                    </section>

                    <section className={`${styles.Img33rd} ${styles.LandingPageImageWrapper}`}>
                        <section className={styles.LandingPageTextWrapper}>
                            <p className={styles.LandingPageText1}>Get them</p>
                            <p className={styles.LandingPageText2}>Visualized</p>
                        </section>
                    </section>

                </section>
            </Route>

            <Redirect to={constants.Path.PageNotFound}>
                <PageNotFound />
            </Redirect>
        </Switch>

    )
}