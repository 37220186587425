import AutoComplete from "react-autocomplete";
import styles from "./CustomAutoC.module.css";
import { useState, useEffect, useRef } from "react";
import { constants } from "../../constants/constants";

export default function CustomAutoC(props) {
    const { field, label, items, action, dispatch, multipleSelection, value,setMessageSettings
    } = props;
    const [searchQuery, setSearchQuery] = useState(multipleSelection === true ? '' : value);
    const removeSearchQuery = () => {
        multipleSelection === false &&
            dispatch({
                type: action,
                payload: ''
            })
        setSearchQuery('');
    }

    const handleSelectValue = (selectedValue) => {
        setSearchQuery(selectedValue);
        dispatch({
            type: action,
            payload: selectedValue
        })
    }
    const handleSelectValueList = (selectedValue) => {
        if(field === constants.field.Year){
            if(value.length >= 3){
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.MAXIMUM_3_VALUES_CAN_BE_SELECTED,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }}
        if (value.length >= 5) {

            // SET ERROR HERE
            // Cannot select more than 5 players
            setMessageSettings({
                showMsg: true,
                messageText: constants.Message.MAXIMUM_5_VALUES_CAN_BE_SELECTED,
                type: constants.Message.TYPE_ERROR
            })
            return;
        }
        
        dispatch({
            type: action,
            payload: value.concat(selectedValue)
        })
    }
    const handleRemoveFromselectedValuesList = (selectedValue) => {
        dispatch({
            type: action,
            payload: value.filter(el => el !== selectedValue)
        })
    }

    return (
        <>
            <div className={`${styles.AutoCompleteWrapper}`} >
                <AutoComplete
                    getItemValue={value => value}
                    items={items.length === 0 ?
                        ['No Values present'] :
                        items.filter(item => item?.toLowerCase()?.includes(searchQuery?.toLowerCase()))}
                    renderItem={(value, isHighlighted) =>
                        <div
                            onClick={(e) => e.stopPropagation()}
                            className={`${styles.autoCOptions}`}
                            key={value}
                            style={{
                                zIndex: 2,
                                background: isHighlighted ? 'lightgray' : 'white'
                            }}>
                            {value}
                        </div>
                    }
                    value={searchQuery}
                    inputProps={{
                        style: {
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "0.4rem",
                            border: "none",
                            outline: "none",
                        }
                    }}
                    renderInput={
                        (props) =>
                            <div className={styles.inputFieldWrapper}>
                                <input
                                    placeholder={label}
                                    {...props}
                                />
                                <button
                                    onClick={removeSearchQuery}
                                    className={styles.closeButton}>x</button>
                            </div>
                    }
                    onSelect={
                        multipleSelection === true ?
                            (selectedValue) => handleSelectValueList(selectedValue)
                            :
                            (selectedValue) => handleSelectValue(selectedValue)
                    }
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {multipleSelection === true ?
                    value.length > 0 ?
                        <div className={styles.selectedValuesListWrapper}>
                            {value.map(el =>
                                <div className={styles.selectedValuesListCompWrapper}>
                                    <span
                                        style={{ padding: "0 0.5rem" }}
                                        className={styles.selectedValuesListComp}>{el}</span>
                                    <button className={styles.closeButton} onClick={() => handleRemoveFromselectedValuesList(el)}>x</button>
                                </div>)}
                        </div> : null : null}
            </div>

        </>
    )
}