import globalStyles from "../../globalStyles/globalStyles.module.css"
import ImgYt from '../../images/ImgYt.jpeg'
export default function Footer() {
    return (

        <footer className={globalStyles.AppFooter}>
            <a href="https://youtu.be/yjv8wMQUq10">
                <img
                className={globalStyles.FooterIcon}
                height="3" width="4" src={ImgYt} />
            </a>
            <p className={globalStyles.FooterText}>
                Visit our Youtube Channel
            </p>
        </footer>
    )
}