import styles from "./ForgotPasswordPage.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css";
import CustomFormInput from "../../components/CustomFormInput/CustomFromInput"
import { useState, useEffect } from "react";
import { backend_api, api_path } from "../../config";
import { constants } from "../../constants/constants";
import { validateEmail } from "../../utils/validateEmail";
import { validatePassword } from "../../utils/validatePassword";
import { validateOtp } from "../../utils/validateOtp";

export default function ForgotPasswordPage({ setMessageSettings, setLoading }) {
    const [email, setEmail] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const generateForgotPasswordOtp = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.forgot_password_get_otp}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.UNAUTHORISED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.UNAUTHORISED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.FORGOT_PASSWORD_OTP_SENT:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_SENT,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    setOtpSent(true);
                    break;
                default:
                    break;
            }

        }
        catch (error) {
            console.log("FORGOT PASSWORD OTP SENT ERROR :", error);
        }
        finally {
            setLoading(false);
        }
    }

    const verifyForgotPasswordOtp = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validatePassword(newPassword)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_PASSWORD,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validateOtp(otp)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_OTP,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.forgot_password_verify_otp}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    newPassword: newPassword,
                    otp: parseInt(otp),
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.UNAUTHORISED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.UNAUTHORISED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_NOT_GENERATED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_NOT_GENERATED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.FORGOT_PASSWORD_RESET_SUCCESSFUL:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.PASSWORD_RESET_SUCCESSFUL,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.log("FORGOT PASSWORD SUBMIT DETAILS ERROR :", error)
        }
        finally {
            setLoading(false);
        }

    }

    return (
        <section className={styles.ForgotPasswordPageWrapper}>
            <section className={globalStyles.InputSectionWrapper}>

                <p className={globalStyles.InputSectionTitle} >Forgot Password</p>
                <CustomFormInput
                    type="text"
                    label={"Enter Email"}
                    labelDisplayFlag={email !== ""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <button onClick={generateForgotPasswordOtp} className={globalStyles.AppBtn}>Generate OTP for Forgot Password</button>

                {otpSent &&
                    <CustomFormInput
                        type="text"
                        label={"Enter OTP"}
                        labelDisplayFlag={otp !== ""}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />}
                {otpSent &&
                    <CustomFormInput
                        type="password"
                        label={"Enter new password"}
                        labelDisplayFlag={newPassword !== ""}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                }
                {otpSent &&
                    <button onClick={verifyForgotPasswordOtp} className={globalStyles.AppBtn}>Reset Password</button>
                }
                {otpSent && <button
                    onClick={generateForgotPasswordOtp}
                    className={globalStyles.LinkButton}>Regenerate OTP</button>}

            </section>

        </section>
    )
}