import styles from "./Message.module.css"
import { constants } from "../../constants/constants"

export default function Message({ messageText, type, setMessageSettings }) {

    return (
        <div
            className={type === constants.Message.TYPE_SUCCESS ?
                `${styles.MessageWrapper} ${styles.MessageWrapperSuccess}` :
                `${styles.MessageWrapper} ${styles.MessageWrapperError}`}>
            <p
                className={type === constants.Message.TYPE_SUCCESS ?
                    `${styles.MessageText} ${styles.MessageTextSuccess}` :
                    `${styles.MessageText} ${styles.MessageTextError}`}>
                {messageText}</p>
            <button
                onClick={() => {
                    setMessageSettings(
                        {
                            showMsg: false,
                            messageText: "",
                            type: ""
                        })
                }}
                className={type === constants.Message.TYPE_SUCCESS ?
                    `${styles.MessageCancelBtn} ${styles.MessageTextSuccess}` :
                    `${styles.MessageCancelBtn} ${styles.MessageTextError}`}>x</button>
        </div>
    )
}   