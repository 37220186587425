import styles from "./ResetPasswordPage.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css";
import CustomFormInput from "../../components/CustomFormInput/CustomFromInput";
import { useState, useEffect } from "react";
import { backend_api, api_path } from "../../config";
import { constants } from "../../constants/constants";
import { validateEmail } from "../../utils/validateEmail";
import { validatePassword } from "../../utils/validatePassword";

export default function ResetPasswordPage({ setMessageSettings, setLoading }) {
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");

    const resetPassword = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validatePassword(oldPassword)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_PASSWORD,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validatePassword(newPassword)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_PASSWORD,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.reset_password}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    newPassword: newPassword,
                    oldPassword: oldPassword,
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.UNAUTHORISED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.UNAUTHORISED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.PASSWORD_RESET_SUCCESSFUL:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.PASSWORD_RESET_SUCCESSFUL,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.log("PASSWORD RESET ERROR :", error)
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <section className={styles.ResetPasswordPageWrapper}>
            <section className={globalStyles.InputSectionWrapper}>

                <p className={globalStyles.InputSectionTitle} >Reset your password</p>
                <CustomFormInput
                    type="text"
                    label={"Enter Email"}
                    labelDisplayFlag={email !== ""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <CustomFormInput
                    type="password"
                    label={"Enter new Password"}
                    labelDisplayFlag={newPassword !== ""}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <CustomFormInput
                    type="password"
                    label={"Enter old Password"}
                    labelDisplayFlag={oldPassword !== ""}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <button onClick={resetPassword} className={globalStyles.AppBtn}>Reset password</button>
            </section>

        </section>
    )
}