export const formMeanObject = (data) => {
    const requiredMeanObj = data.reduce(
        (accumulator, currentObj, index) => {
            for (let key of Object.keys(currentObj)) {
                if (accumulator[`avg_${key}`] === undefined) {
                    accumulator[`avg_${key}`] = 0
                }
                accumulator[`avg_${key}`] += currentObj[key];
                if (index === data.length - 1) {
                    accumulator[`avg_${key}`] =
                        Math.round((accumulator[`avg_${key}`] / (index + 1)) * 100) / 100;
                }
            }
            return accumulator;
        }
        , {})
    return requiredMeanObj;
}