import styles from "./Info.module.css"
export default function Info({ label, data }) {
    return (
        <p className={styles.InfoWrapper}>
            <p className={styles.InfoLabel}>
                {label}
            </p>
            <p className={styles.InfoData}>
                {data}
            </p>
        </p>
    )
}