
console.log(process.env.REACT_APP_ENVR);
export const backend_api = process.env.REACT_APP_ENVR === 'dev' ?
    // 'https://tennischartsbackend-production.up.railway.app' 
    'https://dizzy-bass-capris.cyclic.app'
    : 'https://api.tennisprematch.com';

export const api_path = {
    get_player_names: '/pr/getPlayerNames',
    get_tourney_names: '/pr/getTourneyNames',
    get_data: '/pr/getTcData',
    get_elo: '/pr/getElo',
    register_get_otp: '/or/register/getOtp',
    register_verify_otp: '/or/register//verifyOtp',
    login_get_otp: '/or/login/02/getOtp',
    login_verify_otp: '/or/login/02/verifyOtp',
    login_with_password: '/or/login/01',
    register_verify_otp: '/or/register/verifyOtp',
    reset_password: '/or/resetPassword',
    forgot_password_get_otp: '/or/forgotPassword/getOtp',
    forgot_password_verify_otp: '/or/forgotPassword/verifyOtp',
    logout: '/or/logout',
}
