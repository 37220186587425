import globalStyles from "../../globalStyles/globalStyles.module.css";
import styles from "./NavBar.module.css";
import { constants } from "../../constants/constants";
import { useHistory } from "react-router-dom";
import { backend_api } from "../../config";
import { api_path } from "../../config";
import { useState } from "react";
import Img_logo from "../..//images/Img_logo.png"

export default function NavBar({ loggedIn, setLoading, setMessageSettings }) {
    const [hamburgerToggle, setHamburgerToggle] = useState(false);
    const history = useHistory();
    const onLoginOtpNavClick = () => {
        history.push(constants.Path.LogInOtp); setHamburgerToggle(false);
    };
    const onLogout = async () => {
        try {
            setLoading(true);
            let res = await fetch(`${backend_api}${api_path.logout}`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            });
            res = await res.json();
            if (res.msgCode === constants.MessageCode.SERVICE_UNAVAILABLE) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.SERVER_ERROR,
                    type: constants.Message.TYPE_ERROR
                });
                return;
            }
            if (res.msgCode === constants.MessageCode.LOGGED_OUT_SUCCESSFULLY) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.LOGGED_OUT_SUCCESSFULLY,
                    type: constants.Message.TYPE_SUCCESS
                });
                loggedIn.current = false;
                history.push(constants.Path.LandingPage)
            }
        }
        catch (error) {
            console.log("LOGOUT ERROR :");
            console.log(error);
        }
        finally {
            setLoading(false);
            setHamburgerToggle(false);

        }
    }
    return (
        <header className={styles.AppHeader}>
            <nav className={`${styles.NavBar}`}>
            <img className={styles.IconImage} src={Img_logo} width="5.6" height="2.8" />

                <section className={styles.BtnSection}>
                    {!loggedIn.current &&
                        <button
                            onClick={onLoginOtpNavClick}
                            className={`${globalStyles.HamburgerMenuBtn}`}>Log with OTP</button>
                    }
                    {!loggedIn.current &&
                        <button
                            onClick={() => { history.push(constants.Path.LogInPassword) }}
                            className={`${globalStyles.HamburgerMenuBtn}`}>Log In</button>
                    }
                    {!loggedIn.current &&

                        <button
                            onClick={() => { history.push(constants.Path.SignUp) }}
                            className={`${globalStyles.HamburgerMenuBtn}`}>Sign Up</button>
                    }
                    {loggedIn.current &&

                        <button
                            onClick={onLogout}
                            className={`${globalStyles.HamburgerMenuBtn}`}>Log Out</button>
                    }


                </section>
                <div className={styles.HamburgerIcon}
                    onClick={() => setHamburgerToggle(!hamburgerToggle)}>
                    <div className={styles.bar1}></div>
                    <div className={styles.bar2}></div>
                    <div className={styles.bar3}></div>
                </div>
                {hamburgerToggle &&
                    <div className={styles.HamburgerMenu}>
                        {!loggedIn.current &&
                            <button
                                onClick={onLoginOtpNavClick}
                                className={`${globalStyles.HamburgerMenuBtn}`}>Log with OTP</button>
                        }
                        {!loggedIn.current &&

                            <button
                                onClick={() => {
                                    history.push(constants.Path.LogInPassword)
                                    setHamburgerToggle(false);
                                }}
                                className={`${globalStyles.HamburgerMenuBtn}`}>Log In</button>
                        }
                        {!loggedIn.current &&

                            <button
                                onClick={() => {
                                    history.push(constants.Path.SignUp);
                                    setHamburgerToggle(false);

                                }}
                                className={`${globalStyles.HamburgerMenuBtn}`}>Sign Up</button>
                        }
                        {loggedIn.current &&

                            <button
                                onClick={onLogout}
                                className={`${globalStyles.HamburgerMenuBtn}`}>Log Out</button>
                        }
                    </div>}
            </nav>
        </header>
    )
}