
import { useState } from "react";
import globalStyles from "../../globalStyles/globalStyles.module.css"
export default function CustomFormInput({ label, labelDisplayFlag, value, onChange, type }) {
    const [localType, setLocalType] = useState(type);
    return (
        <div className={globalStyles.FormInputWrapper}>
            <label
                className={`${globalStyles.FormInputLabel} 
                    ${labelDisplayFlag ? globalStyles.LabelActive : globalStyles.LabelInActive}`}
            > {label}</label>
            <input
                style={{ flexBasis: type === "password" ? "70%" : "100%" }}
                type={localType}
                value={value} className={`${globalStyles.FromInput}`}
                placeholder={label}
                onChange={onChange}
            />
            {type === "password" &&
                <div className={globalStyles.ShowPasswordBtnWrapper}>
                    <button
                        className={globalStyles.ShowPasswordBtn}
                        onClick={() => setLocalType(localType === "text" ? "password" : "text")}>
                        {localType === "text" ? "Hide Password" : "Show Password"}
                    </button>
                </div>
            }
        </div>
    )
}