import { useState, useEffect, useRef, useReducer } from "react";
import { constants } from "../../constants/constants";
import styles from "./ChartingComponent.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css"
import CustomAutoC from "../../components/CustomAutoC/CustomAutoC";
import { backend_api, api_path } from "../../config";
import { Loader } from "../../global/Loader/Loader";
import { addQueryParams } from "../../utils/addQueryParams";
import { formMeanObject } from "../../utils/formMeanObject";
import Charts, { getReturnChartDistributn, getServeChartDistributn } from "../../components/Charts/Charts";
import PieCharts from "../../components/PieCharts/PieCharts";
import Info from "../../components/Info/Info";
import MeanTable from "../../components/MeanTable/MeanTable";
import { selectedDataReducerFn } from "./selectedDataReducerFn";
const initialListValues = {
    selected: [],
    ATP: [],
    ATP_CH: [],
    WTA: [],
    WTA_CH: []
}
const requiredKeysForMean = [
    "player_1st_In_prc",
    "player_1stprc",
    "player_2ndprc",
    "player_1st_retprc",
    "player_2nd_retprc",
    "player_bpSavedprc",
    "opp_bpSavedprc",
    "player_dr",
]
const getResultsDistributn = (data) => {
    const wins = data.filter(el => el.result === 'W').length;
    const losses = data.filter(el => el.result === 'L').length;
    return [{ key: 'Wins', value: wins },
    { key: 'Losses', value: losses }]
}
const emptyValues = ['', ' ', null, [], undefined, ['']]

const initialSelectedData = {
    tour: constants.autoCData.Tour[0],
    circuit: constants.autoCData.Circuit[0],
    playerName: 'Rafael Nadal',
    surface: constants.autoCData.Surface[1],
    result: '',
    selectedYears: [constants.autoCData.Year[0]],
    selectedOpponents: [],
    selectedTourneys: [],
    selectedRounds: [],
    stat: constants.StatsOptions.ServePoints
}
const getElo = async (tour, playerName) => {
    try {
        let url = `${backend_api}${api_path.get_elo}?`;
        url = addQueryParams(url, {
            tour: tour,
            playerName: playerName
        });
        let data = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        })
        data = data.json();
        return data
    }
    catch (error) {
        console.log("ERROR: getElo:", error);
        return null;
    }
}
export default function ChartingComponent({ setMessageSettings, setLoading }) {
    const isMounted = useRef({
        tourAndCircuitChange: false,
    })
    const [selectedData, dispatch] = useReducer(selectedDataReducerFn, initialSelectedData);
    const [playerNamesItems, setPlayerNamesItems] = useState({ ...initialListValues })
    const [tourneyNamesItems, setTourneyNamesItems] = useState({ ...initialListValues }); const [stat, setStat] = useState(constants.StatsOptions.ServePoints);
    const [data, setData] = useState([]);
    const [elo, setElo] = useState(null)
    const [serveChartDistributn, setServeChartDistributn] = useState(null);
    const [returnChartDistributn, setReturnChartDistributn] = useState(null);
    const [winLossDistributn, setWinLossDistributn] = useState(null)
    const [showingResultsFor, setShowingresultsFor] = useState('');
    const [dataForMean, setDataForMean] = useState([]);

    const getShowingResultsForString = (data) => {
        let requiredString = '';
        for (let item of Object.entries(data)) {
            if (item[0] === 'stat') continue;
            !emptyValues.includes(item[1]) ?
                !(item[1] instanceof Array) ?
                    requiredString += `${item[0]} : ${item[1]}, ` :
                    item[1].length === 0 ? requiredString += '' :
                        requiredString += `${item[0]} : ${item[1].map(el => `${el},`).join("")}` :
                requiredString += ''
        }
        return requiredString;
    }

    const getListKey = () => {
        const circuitString = selectedData.circuit === 'main' ? '' : '_CH';
        return `${selectedData.tour}${circuitString}`
    }

    const getPlayerNameList = async () => {
        // returns null is any of the required 3 values are empty
        const playerNameListKey = getListKey();
        if (playerNameListKey === null) {
            // SET ERROR HERE
            setMessageSettings({
                showMsg: true,
                messageText: constants.Message.TOUR_OR_CIRCUIT_NOT_SELECTED,
                type: constants.Message.TYPE_ERROR
            })
            return;
        }
        if (playerNamesItems[playerNameListKey].length === 0) {
            try {
                let url = `${backend_api}${api_path.get_player_names}?`;
                url = addQueryParams(url, {
                    tour: selectedData.tour,
                    circuit: selectedData.circuit
                })
                let data = await fetch(url,
                    {
                        method: 'GET',
                        credentials: 'include'
                    });
                data = await data.json();
                if (data.msgCode === constants.MessageCode.SERVICE_UNAVAILABLE) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.SERVICE_UNAVAILABLE,
                        type: constants.Message.TYPE_ERROR
                    })
                    return;
                }
                if (data.msgCode === constants.MessageCode.BAD_REQUEST) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    return;
                }
                if (data.msgCode === constants.MessageCode.DAILY_API_LIMIT_EXCEEDED) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.DAILY_API_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    return;
                }
                if (data.msgCode === constants.MessageCode.UNAUTHORISED) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.UNAUTHORISED,
                        type: constants.Message.TYPE_ERROR
                    })
                    return;
                }
                data = data?.stats;
                setPlayerNamesItems({
                    ...playerNamesItems,
                    [playerNameListKey]: data,
                    selected: data,
                })
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            const data = playerNamesItems[playerNameListKey];
            setPlayerNamesItems({
                ...playerNamesItems,
                selected: data,
            })
        }
    }

    const getTourneyNameList = async () => {
        // returns null is any of the required 3 values are empty
        const tourneyNameListKey = getListKey();
        if (tourneyNameListKey === null) {
            // SET ERROR HERE 
            setMessageSettings({
                showMsg: true,
                messageText: constants.Message.TOUR_OR_CIRCUIT_NOT_SELECTED,
                type: constants.Message.TYPE_ERROR
            })
            return;
        }
        if (tourneyNamesItems[tourneyNameListKey].length === 0) {
            try {
                let url = `${backend_api}${api_path.get_tourney_names}?`;
                url = addQueryParams(url, {
                    tour: selectedData.tour,
                    circuit: selectedData.circuit
                })
                let data = await fetch(url,
                    {
                        method: 'GET',
                        credentials: 'include'
                    });
                data = await data.json();
                if (data.msgCode === constants.MessageCode.SERVICE_UNAVAILABLE) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.SERVICE_UNAVAILABLE,
                        type: constants.Message.TYPE_ERROR
                    })
                    return [];
                }
                if (data.msgCode === constants.MessageCode.BAD_REQUEST) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    return [];
                }
                if (data.msgCode === constants.MessageCode.DAILY_API_LIMIT_EXCEEDED) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.DAILY_API_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    return [];
                }
                if (data.msgCode === constants.MessageCode.UNAUTHORISED) {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.UNAUTHORISED,
                        type: constants.Message.TYPE_ERROR
                    })
                }
                data = data?.stats;
                setTourneyNamesItems({
                    ...tourneyNamesItems,
                    [tourneyNameListKey]: data,
                    selected: data,
                })
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            const data = tourneyNamesItems[tourneyNameListKey];
            setTourneyNamesItems({
                ...tourneyNamesItems,
                selected: data,
            })
        }
    }

    const getData = async () => {
        try {
            setLoading(true);
            const inputCheck = emptyValues.includes(selectedData.tour) ?
                (() => {
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.SELECT_TOUR_VALUE,
                        type: constants.Message.TYPE_ERROR
                    })
                    return false
                })() :
                emptyValues.includes(selectedData.circuit) ?
                    (() => {
                        setMessageSettings({
                            showMsg: true,
                            messageText: constants.Message.SELECT_CIRCUIT_VALUE,
                            type: constants.Message.TYPE_ERROR
                        })
                        return false;
                    })() :
                    emptyValues.includes(selectedData.playerName) ?
                        (() => {
                            setMessageSettings({
                                showMsg: true,
                                messageText: constants.Message.SELECT_PLAYERNAME_VALUE,
                                type: constants.Message.TYPE_ERROR
                            })
                        })() :
                        selectedData.selectedYears.length === 0 && emptyValues.includes(selectedData.surface) ?
                            (() => {
                                setMessageSettings({
                                    showMsg: true,
                                    messageText: constants.Message.SELECT_YEAR_OR_SURFACE_VALUE,
                                    type: constants.Message.TYPE_ERROR
                                })
                                return false;
                            })()
                            : (() => {
                                return true;
                            })()

            if (!inputCheck) {
                return;
            }
            let url = `${backend_api}${api_path.get_data}?`;
            url = addQueryParams(url, {
                tour: selectedData.tour,
                circuit: selectedData.circuit,
                year: selectedData.selectedYears,
                playerName: selectedData.playerName,
                surface: selectedData.surface,
                result: selectedData.result?.split("")[0] ?? '',
                oppNames: selectedData.selectedOpponents,
                tourneyNames: selectedData.selectedTourneys,
                rounds: selectedData.selectedRounds
            });
            let data = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include'
                });
            data = await data.json();
            // SET ERROR HERE
            // IF NO VALID COOKIE
            if (data.msgCode === constants.MessageCode.SERVICE_UNAVAILABLE) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.SERVICE_UNAVAILABLE,
                    type: constants.Message.TYPE_ERROR
                })
                return [];
            }
            if (data.msgCode === constants.MessageCode.BAD_REQUEST) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.BAD_REQUEST,
                    type: constants.Message.TYPE_ERROR
                })
                return [];
            }
            if (data.msgCode === constants.MessageCode.DAILY_API_LIMIT_EXCEEDED) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.DAILY_API_LIMIT_EXCEEDED,
                    type: constants.Message.TYPE_ERROR
                })
                return [];
            }
            if (data.msgCode === constants.MessageCode.UNAUTHORISED) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.UNAUTHORISED,
                    type: constants.Message.TYPE_ERROR
                })
            }

            data = data?.stats ?? [];
            data = data.map(el => ({
                ...el,
                "round_and_result": `${el.round}, ${el.result === 'W' ? 'Win' : 'Loss'}`,
                "tourney_and_year": `${el.tourney_name}, ${el.year}`,
                "player_bpConverted": el.opp_bpFaced - el.opp_bpSaved,
            }))
            const eloData = await getElo(selectedData.tour, selectedData.playerName);
            setElo(eloData.stats[0]);
            setData(data);
            setShowingresultsFor(getShowingResultsForString(selectedData));
            console.log(data)
            if (data.length > 0) {
                setWinLossDistributn(getResultsDistributn(data));
                setServeChartDistributn(getServeChartDistributn(data[0]));
                setReturnChartDistributn(getReturnChartDistributn(data[0]));
            }
            else {
                setWinLossDistributn(null);
                setServeChartDistributn(null);
                setReturnChartDistributn(null);
            }
        }
        catch (error) {
            console.log("ERROR : getData");
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const setValueLists = async () => {
        try {
            // setClearSearchQuery(!clearSearchQuery);
            await getPlayerNameList();
            await getTourneyNameList();
        }
        catch (error) {
            console.log(error);
            // ERROR MESSAGE HERE 
        }
        finally {
        }
    }

    const addMeanRow = () => {
        if (dataForMean.length >= 10) {
            setMessageSettings({
                showMsg: true,
                messageText: constants.Message.MAXIMUM_10_ROWS_CAN_BE_ADDED,
                type: constants.Message.TYPE_ERROR
            })
            return;
        }
        const requiredObjectsForMean = data.map(el => {
            const requiredObject = {};
            for (let key of requiredKeysForMean) {
                requiredObject[key] = el[key];
            }
            return requiredObject;
        })
        let requiredMeanValuesObject = formMeanObject(requiredObjectsForMean);
        requiredMeanValuesObject = { ...requiredMeanValuesObject, combination: showingResultsFor }
        let updatedDataForMean = dataForMean.concat([requiredMeanValuesObject]);
        updatedDataForMean = updatedDataForMean.map((el, index) =>
        ({
            ...el,
            id: index
        }))
        setDataForMean(updatedDataForMean);
    }

    useEffect(() => {
        (async () => {
            await setValueLists();
        })()
    }, [selectedData.tour, selectedData.circuit])

    useEffect(() => {
        (async () => {
            await getData();
            await setValueLists()
        })()
    }, [])

    return (
        <section className={`${styles.InputsAndChartsWrapper}`}>
            <section className={`${styles.InputSection}`}>
                {/* TOUR AutoComplete */}
                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetTour}
                    multipleSelection={false}
                    value={selectedData.tour}
                    field={constants.field.Tour}
                    label={constants.autoCLabel.Tour}
                    items={constants.autoCData.Tour}
                />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetCircuit}
                    multipleSelection={false}
                    value={selectedData.circuit}
                    field={constants.field.Circuit}
                    label={constants.autoCLabel.Circuit}
                    items={constants.autoCData.Circuit}
                />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetResult}
                    multipleSelection={false}
                    value={selectedData.result} field={constants.field.Result}
                    label={constants.autoCLabel.Result}
                    items={constants.autoCData.Result}
                />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetSurface}
                    multipleSelection={false}
                    value={selectedData.surface} field={constants.field.Surface}
                    label={constants.autoCLabel.Surface}
                    items={constants.autoCData.Surface} />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetPlayername}
                    multipleSelection={false}
                    value={selectedData.playerName} field={constants.field.PlayerName}
                    label={constants.autoCLabel.PlayerName}
                    items={playerNamesItems.selected} />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetYear}
                    multipleSelection={true}
                    value={selectedData.selectedYears}
                    field={constants.field.Year}
                    label={constants.autoCLabel.Year}
                    items={constants.autoCData.Year} />



                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetRounds}
                    multipleSelection={true}
                    value={selectedData.selectedRounds} field={constants.field.Round}
                    label={constants.autoCLabel.Round}
                    items={constants.autoCData.Round} />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetOpponents}
                    multipleSelection={true}
                    value={selectedData.selectedOpponents} field={constants.field.OpponentNames}
                    label={constants.autoCLabel.OpponentNames}
                    items={playerNamesItems.selected} />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetTourneys}
                    multipleSelection={true}
                    value={selectedData.selectedTourneys} field={constants.field.TourneyNames}
                    label={constants.autoCLabel.TourneyNames}
                    items={tourneyNamesItems.selected} />

                <CustomAutoC
                    setMessageSettings={setMessageSettings}
                    dispatch={dispatch}
                    action={constants.Action.SetStatOptions}
                    multipleSelection={false}
                    field={constants.field.StatsOption}
                    label={constants.autoCLabel.StatsOption}
                    items={constants.autoCData.StatsOption}
                    value={selectedData.stat} />

                <section className={`${styles.searchBtnWrapper}`}>
                    <button onClick={getData} className={`${globalStyles.AppBtn}`}>Search</button>
                </section>


            </section>


            {data.length == 0 ?
                <section className={styles.NoChartPlaceHolder}>
                    <p className={styles.NoChartPlaceHolderText}></p>
                </section>
                :
                <section className={`${styles.ChartsSection}`}>
                    <section className={styles.ChartsWrapper}>
                        <Charts
                            data={data ?? []}
                            stat={selectedData.stat}
                            setServeChartDistributn={setServeChartDistributn}
                            setReturnChartDistributn={setReturnChartDistributn}
                        />
                    </section>
                </section>

            }


            <section className={styles.ShowingResultsFor}>
                <p className={styles.ShowingResultsForLabel}>
                    <p className={styles.LabelWrapper}>
                        Showing results for
                    </p>
                </p>
                <p className={styles.ShowingResultsForData}>
                    {showingResultsFor}
                </p>
            </section>


            <section className={styles.EloWrapper}>
                <div className={styles.EloColumn}>
                    <p className={styles.EloText}>Elo Rating</p>
                    <p className={styles.EloNumber}>{elo?.Elo ?? 'NA'}</p>
                </div>

                <div className={styles.EloColumn}>
                    <p className={styles.EloText}>Hard Elo</p>
                    <p className={styles.EloNumber}>{elo?.HardRaw ?? 'NA'}</p>
                </div>

                <div className={styles.EloColumn}>
                    <p className={styles.EloText}>Clay Elo</p>
                    <p className={styles.EloNumber}>{elo?.ClayRaw ?? 'NA'}</p>
                </div>

                <div className={styles.EloColumn}>
                    <p className={styles.EloText}>Grass Elo</p>
                    <p className={styles.EloNumber}>{elo?.GrassRaw ?? 'NA'}</p>
                </div>
            </section>


            <section className={styles.SetIntoAvgBtnWrapper}>
                <button
                    onClick={addMeanRow}
                    className={globalStyles.AppBtn}>
                    Add Data In Average Table
                </button>
            </section>

            <section className={styles.MeanTableSection}>
                <MeanTable dataForMean={dataForMean} setDataForMean={setDataForMean} />
            </section>

            <section className={`${styles.PieChartsSection}`}>

                {winLossDistributn !== null && <section className={styles.PieChartsWrapper}>
                    <PieCharts
                        field={constants.field.Result}
                        data={winLossDistributn ?? []} />
                    <Info
                        label={constants.InfoLabel.TotalMatches}
                        data={(winLossDistributn?.[0]?.value + winLossDistributn?.[1]?.value) ?? ''} />
                </section>}

                {serveChartDistributn !== null &&
                    <section className={styles.PieChartsWrapper}>

                        <PieCharts
                            field={constants.field.ServePoints}
                            data={serveChartDistributn?.[0] ?? []} />
                        <Info
                            label={constants.InfoLabel.MatchDetails}
                            data={serveChartDistributn?.[1]?.opp_name_tourney_year ?? ''} />


                        <p className={styles.DistributionInfo}>
                            {/* <button className={globalStyles.InfoIcon}>i</button> */}
                            Click on a particular Serve Barchart to get its points distribution
                        </p>
                    </section>}

                {returnChartDistributn !== null &&
                    <section className={styles.PieChartsWrapper}>

                        <PieCharts
                            field={constants.field.ReturnPoints}
                            data={returnChartDistributn?.[0] ?? []}
                        />
                        <Info
                            label={constants.InfoLabel.MatchDetails}
                            data={returnChartDistributn?.[1]?.opp_name_tourney_year ?? ''} />
                        <p className={styles.DistributionInfo}>
                            {/* <button className={globalStyles.InfoIcon}>i</button> */}
                            Click on a particular Return Barchart to get its points distribution
                        </p>

                    </section>}
            </section>



        </section>
    )

}