const getBarChartConfig = (configObj) => ({
    chartType: configObj.chartType,
    xAxis: configObj.xAxis.map(el => ({
        xAxisId: el[0],
        datakey: el[1],
    })),
    yAxis: {
        unit: configObj.yAxis[0],
        type: configObj.yAxis[1],
        fill: configObj.yAxis[2],
        fontSize: configObj.yAxis[3],
    },
    bars: configObj.bars.map(bar => ({
        barWidth: bar[0][0],
        name: bar[0][1],
        dataKey: bar[0][2],
        fill: bar[0][3],
        labelConfig: {
            dataKey: bar[1][0],
            fill: bar[1][1],
            fontSize: bar[1][2],
        }
    })
    )
})

const getLineChartConfig = (configObj) => ({
    chartType: configObj.chartType,
    xAxis: configObj.xAxis.map(el => ({
        xAxisId: el[0],
        datakey: el[1],
    })),
    yAxis: {
        unit: configObj.yAxis[0],
        type: configObj.yAxis[1],
        fill: configObj.yAxis[2],
        fontSize: configObj.yAxis[3],
    },
    lines: configObj.lines.map(line => ({
        strokeWidth: line[0][0],
        name: line[0][1],
        dataKey: line[0][2],
        stroke: line[0][3],
        labelConfig: {
            fill: line[1][0],
        }
    })
    )
})
const xAxisFields = [
    ["0", "opp_name"],
    ["1", "score"],
    ["2", "tourney_and_year"],
    ["3", "round_and_result"]]
export const ChartsConfig = {
    ServePoints: getBarChartConfig({
        chartType: "Bar Chart",
        xAxis: xAxisFields,
        yAxis: ["%", "number", "rgb(255,255,255)", "12.5px"],
        bars: [
            [[25, "1st Serves In", "player_1st_In_prc", "rgb(125, 248, 201)"],
            ["player_1st_In_prc", "rgb(255,255,255)", '12.5px']],
            [[25, "1st Serves Won", "player_1stprc", "rgb(9, 126, 204)"],
            ["player_1stprc", "rgb(255,255,255)", '12.5px']],
            [[25, "2nd Serves Won", "player_2ndprc", "rgb(7, 192, 201)"],
            ["player_2ndprc", "rgb(255,255,255)", '12.5px']],
        ]
    }),

    ReturnPoints: getBarChartConfig({
        chartType: "Bar Chart",
        xAxis: xAxisFields,
        yAxis: ["%", "number", "rgb(255,255,255)", "12.5px"],
        bars: [
            [[25, "1st Serves Returns Won", "player_1st_retprc", "rgb(173, 201, 122)"],
            ["player_1st_retprc", "rgb(255,255,255)", '12.5px']],
            [[25, "2nd Serves Returns Won", "player_2nd_retprc", "rgb(252, 126, 164)"],
            ["player_2nd_retprc", "rgb(255,255,255)", '12.5px']]
        ]
    }),


    BreakPoints: getBarChartConfig({
        chartType: "Bar Chart",
        xAxis: xAxisFields,
        yAxis: ["", "number", "rgb(255,255,255)", "12.5px"],
        bars: [
            [[25, "Break points faced", "player_bpFaced", "rgb(255, 53, 53)"],
            ["player_bpFaced", "rgb(255,255,255)", '12.5px']],
            [[25, "Break points saved", "player_bpSaved", "rgb(0, 238, 198)"],
            ["player_bpSaved", "rgb(255,255,255)", '12.5px']]
        ]
    }),

    BreakPointsConverted: getBarChartConfig({
        chartType: "Bar Chart",
        xAxis: xAxisFields,
        yAxis: ["", "number", "rgb(255,255,255)", "12.5px"],
        bars: [
            [[25, "Break points Created", "opp_bpFaced", "rgb(0, 238, 198)"],
            ["opp_bpFaced", "rgb(255,255,255)", '12.5px']],
            [[25, "Break points Converted", "player_bpConverted", "rgb(255, 255, 255)"],
            ["player_bpConverted", "rgb(255,255,255)", '12.5px']]
        ]
    }),

    AcesAndDoubleFaults: getBarChartConfig({
        chartType: "Bar Chart",
        xAxis: xAxisFields,
        yAxis: ["", "number", "rgb(255,255,255)", "12.5px"],
        bars: [
            [[25, "Aces", "player_ace", "rgb(0, 238, 198)"],
            ["player_ace", "rgb(255,255,255)", '12.5px']],
            [[25, "Double Faults", "player_df", "rgb(255, 53, 53)"],
            ["player_df", "rgb(255,255,255)", '12.5px']]
        ]
    }),

    DominanceRatio: getLineChartConfig({
        chartType: "Line Chart",
        xAxis: xAxisFields,
        yAxis: ["", "number", "rgb(255,255,255)", "12.5px"],
        lines: [
            [[2, "Dominance Ratio", "player_dr", "rgb(0, 255, 157)"],
            ["rgb(255, 255, 255)"]
            ]
        ]
    })


}