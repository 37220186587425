import { constants } from "../../constants/constants"

export const selectedDataReducerFn = (currentState, action) => {
    switch (action.type) {
        case constants.Action.ClearOnTourCircuitChange:
            return {
                ...currentState,
                ...action.payload
            }
        case constants.Action.SetTour:
            return {
                ...currentState,
                tour: action.payload
            }
        case constants.Action.SetCircuit:
            return {
                ...currentState,
                circuit: action.payload
            }
        case constants.Action.SetPlayername:
            return {
                ...currentState,
                playerName: action.payload
            }
        case constants.Action.SetSurface:
            return {
                ...currentState,
                surface: action.payload
            }
        case constants.Action.SetResult:
            return {
                ...currentState,
                result: action.payload
            }
        case constants.Action.SetYear:
            return {
                ...currentState,
                selectedYears: action.payload
            }
        case constants.Action.SetOpponents:
            return {
                ...currentState,
                selectedOpponents: action.payload
            }
        case constants.Action.SetTourneys:
            return {
                ...currentState,
                selectedTourneys: action.payload
            }
        case constants.Action.SetRounds:
            return {
                ...currentState,
                selectedRounds: action.payload
            }
        case constants.Action.SetStatOptions:
            return {
                ...currentState,
                stat: action.payload
            }
        default:
            return {
                ...currentState
            }
    }

}