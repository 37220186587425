import styles from "./LoginWithPasswordPage.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css";
import CustomFormInput from "../../components/CustomFormInput/CustomFromInput";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { backend_api, api_path } from "../../config";
import { constants } from "../../constants/constants";
import { validateEmail } from "../../utils/validateEmail";
import { validatePassword } from "../../utils/validatePassword";

export default function LoginWithPasswordPage({ setMessageSettings, setLoading, loggedIn }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory()

    const loginWithPassword = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validatePassword(password)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_PASSWORD,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.login_with_password}`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    password: password,
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_NOT_GENERATED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_NOT_GENERATED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_VERIFY_LIMIT_REACHED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_VERIFY_LIMIT_REACHED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.INCORRECT_OTP:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.INCORRECT_OTP,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.LOGGED_IN_SUCCESSFULLY:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.LOGIN_OTP_VERIFIED,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    loggedIn.current = true;
                    history.push(constants.Path.Stats);
                default:
                    break;
            }
        }
        catch (error) {
            console.log("LOG IN W PASSWORD ERROR :", error)
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <section className={styles.LoginWithPasswordPageWrapper}>
            <section className={globalStyles.InputSectionWrapper}>

                <p className={globalStyles.InputSectionTitle} >Log In to tennisprematch.com </p>
                <CustomFormInput
                    type="text"
                    label={"Enter Email"}
                    labelDisplayFlag={email !== ""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <CustomFormInput
                    type="password"
                    label={"Enter password"}
                    labelDisplayFlag={password !== ""}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={loginWithPassword} className={globalStyles.AppBtn}>Log In</button>
                <button
                    onClick={() => { history.push(constants.Path.ForgotPassword) }}
                    className={globalStyles.LinkButton}>Forgot password ?</button>
                <button
                    onClick={() => { history.push(constants.Path.ResetPassword) }}
                    className={globalStyles.LinkButton}>Reset password</button>
            </section>

        </section>
    )
}