import { useState, useRef } from "react";
import globalStyles from "../../globalStyles/globalStyles.module.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ChartingComponent from "../ChartingComponent/ChartingComponent";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import Message from "../../components/Message/Message";
import LandingPage from "../LandingPage/LandingPage";
import { constants } from "../../constants/constants";
import { Loader } from "../../global/Loader/Loader";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";

export default function Routing() {
    const [messageSettings, setMessageSettings] = useState({
        showMsg: false,
        messageText: "",
        type: ""
    })
    const [loading, setLoading] = useState(false);
    const loggedIn = useRef(false)
    return (
        <section>
            {loading && Loader}

            {messageSettings.showMsg &&

                <Message
                    messageText={messageSettings.messageText}
                    type={messageSettings.type}
                    setMessageSettings={setMessageSettings}
                />}
            <div className={globalStyles.MarginReplacingNavBar}></div>
            <Router>
                <NavBar
                    setMessageSettings={setMessageSettings}
                    loggedIn={loggedIn}
                    setLoading={setLoading}
                />
                <main className={`${globalStyles.Container}`}>
                    <Switch>
                        <PrivateRoute loggedIn={loggedIn} path={constants.Path.Stats} exact>
                            <ChartingComponent
                                setMessageSettings={setMessageSettings}
                                setLoading={setLoading}
                            />
                        </PrivateRoute>

                        <Route path={constants.Path.LandingPage}>
                            <LandingPage
                                setMessageSettings={setMessageSettings}
                                setLoading={setLoading}
                                loggedIn={loggedIn}
                            />
                        </Route>

                    </Switch>
                </main>
            </Router>


            <Footer />
        </section>
    )
}