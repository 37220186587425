import styles from "./LoginWithOtpPage.module.css";
import globalStyles from "../../globalStyles/globalStyles.module.css";
import CustomFormInput from "../../components/CustomFormInput/CustomFromInput";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { backend_api, api_path } from "../../config";
import { constants } from "../../constants/constants";
import { validateEmail } from "../../utils/validateEmail";
import { validateOtp } from "../../utils/validateOtp";
import { validatePassword } from "../../utils/validatePassword";

export default function LoginWithOtpPage({ setMessageSettings, setLoading, loggedIn }) {
    const [email, setEmail] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState("");
    const history = useHistory();

    const generateLoginOtp = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.login_get_otp}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.LOGIN_OTP_SENT:
                    setOtpSent(true);
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_SENT,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.log("GET EMAIL VERIF OTP ERROR :", error)
        }
        finally {
            setLoading(false)
        }
    }

    const verifyLoginOtp = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_EMAIL,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            if (!validateOtp(otp)) {
                setMessageSettings({
                    showMsg: true,
                    messageText: constants.Message.ENTER_A_VALID_OTP,
                    type: constants.Message.TYPE_ERROR
                })
                return;
            }
            let res = await fetch(`${backend_api}${api_path.login_verify_otp}`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    otp: parseInt(otp),
                })
            });
            res = await res.json();
            switch (res.msgCode) {
                case constants.MessageCode.BAD_REQUEST:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.BAD_REQUEST,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_LIMIT_EXCEEDED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_LIMIT_EXCEEDED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_NOT_GENERATED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_NOT_GENERATED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.OTP_VERIFY_LIMIT_REACHED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.OTP_VERIFY_LIMIT_REACHED,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.INCORRECT_OTP:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.INCORRECT_OTP,
                        type: constants.Message.TYPE_ERROR
                    })
                    break;
                case constants.MessageCode.LOGIN_OTP_VERIFIED:
                    setMessageSettings({
                        showMsg: true,
                        messageText: constants.Message.LOGIN_OTP_VERIFIED,
                        type: constants.Message.TYPE_SUCCESS
                    })
                    loggedIn.current = true;
                    history.push(constants.Path.Stats);
                default:
                    break;
            }
        }
        catch (error) {
            console.log("EMAIL VERIF OTP SUBMIT ERROR", error)
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <section className={styles.LoginWithOtpPageWrapper}>
            <section className={globalStyles.InputSectionWrapper}>

                <p className={globalStyles.InputSectionTitle} >Log In to tennisprematch.com </p>
                <CustomFormInput
                    type="text"
                    label={"Enter Email"}
                    labelDisplayFlag={email !== ""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                {!otpSent && <button
                    onClick={generateLoginOtp}
                    className={globalStyles.AppBtn}>Generate OTP for Log In </button>}
                {otpSent &&
                    <>
                        <CustomFormInput
                            type="text"
                            label={"Enter OTP"}
                            labelDisplayFlag={otp !== ""}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <button onClick={verifyLoginOtp} className={globalStyles.AppBtn}>Submit OTP</button>
                    </>}
                {otpSent && <button
                    onClick={generateLoginOtp}
                    className={globalStyles.LinkButton}>Regenerate OTP</button>}
            </section>
        </section>
    )
}