export const addQueryParams = (url, queryParamsObj) => {
    const keys = Object.keys(queryParamsObj);
    keys.forEach((key, index) => {
        if (index === 0) {
            url += `${key}=${queryParamsObj[key]}`
        }
        else {
            url += `&${key}=${queryParamsObj[key]}`
        }
    })
    return url
}