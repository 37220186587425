import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import styles from "./PieCharts.module.css"
import { constants } from '../../constants/constants';


const getColors = (field) => {
    switch (field) {
        case constants.field.Result:
            return ["rgb(0, 238, 198)", "rgb(255, 53, 53)"];
        case constants.field.ServePoints:
            return ["rgb(9, 126, 204)", "rgba(9, 126, 204,0.65)", "rgb(255, 53, 53)", "rgb(7, 192, 201)", "rgba(7, 192, 201,0.65)"]
        case constants.field.ReturnPoints:
            return ["rgb(173, 201, 122)", "rgba(173, 201, 122,0.65)", "rgb(255, 53, 53)", "rgb(252, 126, 164)", "rgba(252, 126, 164, 0.65)"]
    }
}
const customLabel = (props) => {
    const svgString = `${props.payload.payload.value} ${props.payload.payload.key} `;
    return <svg >
        <g>
            <rect x={props.x} y={props.y} width={svgString.length * 7.5} height="30" fill="rgb(200,200,200)"></rect>
            <text x={props.x + 5} y={props.y + 20} fontFamily="Verdana" fontSize="12.5" fill="rgb(0,0,0)">{svgString}</text>
        </g>
    </svg>
}
export default function PieCharts(props) {
    const { data, field } = props;
    const colors = getColors(field)
    return (
        <section className={styles.ResponsiveContainerWrapper}>
            <ResponsiveContainer height="100%" width="100%">
                <PieChart
                >
                    <Pie
                        data={data}
                        cx="33.33%"
                        cy="50%"
                        outerRadius={100}
                        innerRadius={75}
                        dataKey="value"
                        label={customLabel}
                    >
                        {data.map((el, index) =>
                            <Cell fill={colors[index]} />
                        )}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </section>
    )
}